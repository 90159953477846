<template>
  <div>
    <!-- table -->
    <data-table
      :columns="columns"
      requesturl="/barcodes"
      :server-params-modifier="serverParamsModifier"
    >
      <template
        #actions="{ rowData }"
      >
        <!-- Column: Action -->
        <span>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              :disabled="rowData.status !== 'Created'"
              @click="selectedBarcode = rowData._id; $refs.deleteBarcodeRef.show()"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Delete Barcode</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.isObsolete!==true"
              @click="obsoleteKitId = rowData._id;obseleteKitTypeID = (rowData.kitType==='covid')?rowData.corBarcode:rowData.duBarcode; $refs.setObsolete.show()"
            >
              <feather-icon
                icon="XSquareIcon"
                class="mr-50"
              />
              <span>Obsolete Kit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              @click="obsoleteKitId = rowData._id;obseleteKitTypeID = (rowData.kitType==='covid')?rowData.corBarcode:rowData.duBarcode; $refs.setUnObsolete.show()"
            >
              <feather-icon
                icon="CheckSquareIcon"
                class="mr-50"
              />
              <span>Un-obsolete Kit</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>

    </data-table>
    <b-modal
      ref="deleteBarcodeRef"
      title="Delete Barcode"
      @cancel="selectedBarcode = null"
      @ok="deleteBarcode"
    >
      Are you sure you want to delete barcode <b>{{ selectedBarcodeNumber }} </b> ?
    </b-modal>
    <b-modal
      ref="setObsolete"
      ok-variant="danger"
      title="Set kit as obsolete"
      ok-title="Yes"
      @ok="obsoleteKit"
    >
      <p>
        Are you sure you want to make the kit obsolete?
      </p>
    </b-modal>
    <b-modal
      ref="setUnObsolete"
      ok-variant="danger"
      title="Set kit as unobsolete"
      ok-title="Yes"
      @ok="unObsoleteKit"
    >
      <p>
        Are you sure you want to make the kit unobsolete?
      </p>
    </b-modal>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue'

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      selectedBarcode: null,
      pageLength: 20,
      columns: [
        {
          label: 'DU Barcode',
          field: 'duBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'ST Barcode',
          field: 'stBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'SA Barcode',
          field: 'saBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'COR Barcode',
          field: 'corBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Type',
          field: 'kitType',
          formatFn: value => (value.trim().replace(/^\w/, c => c.toUpperCase())),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Account ID',
          field: 'accountID',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit User ID',
          field: 'kitUser',
          formatFn: value => (value === '' || value.length === 0 ? 'Not Registered' : value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Referred By',
          field: 'referredBy',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Obsolete Status',
          field: 'isObsolete',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
            filterDropdownItems: [{ value: true, text: 'Obsolete' }, { value: false, text: 'Unobsolete' }],
          },
          formatFn: (data, filterString) => (data === filterString),
        },
        {
          label: 'Tags',
          field: 'tags',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },
  computed: {
    selectedBarcodeData() {
      if (!this.rows || !this.selectedBarcode) return null
      // eslint-disable-next-line no-underscore-dangle
      return this.rows.filter(barcode => barcode._id === this.selectedBarcode)[0]
    },
    selectedBarcodeNumber() {
      if (!this.selectedBarcodeData) return null
      if (this.selectedBarcodeData.kitType === 'covid') {
        return this.selectedBarcodeData.corBarcode
      }
      return this.selectedBarcodeData.duBarcode
    },
  },
  methods: {
    serverParamsModifier(serverParams) {
      const serverParamsClone = JSON.parse(JSON.stringify(serverParams))
      return serverParamsClone
    },
    deleteBarcode() {
      this.showLoading()
      // eslint-disable-next-line no-underscore-dangle
      this.$http.delete(`/barcodes/${this.selectedBarcode}`).then(() => {
        // eslint-disable-next-line no-underscore-dangle
        this.$bvToast.toast(`${this.selectedBarcodeNumber} successfully deleted`, {
          title: 'Barcode deleted',
          variant: 'success',
          solid: true,
        })
        this.selectedBarcode = null
        // this.getTableData()
      }).catch(this.handleError)
    },
    obsoleteKit() {
      this.$http.patch(`barcode/${this.obsoleteKitId}/obsolete`).then(() => {
        this.$bvToast.toast(`${this.obseleteKitTypeID} has been made obsolete`, {
          title: 'Success!',
          variant: 'success',
          autoHideDelay: 5000,
          solid: true,
        })
        // this.getTableData()
      }).catch(error => { this.handleError(error) })
    },
    unObsoleteKit() {
      this.$http.patch(`barcode/${this.obsoleteKitId}/unObsolete`).then(() => {
        this.$bvToast.toast(`${this.obseleteKitTypeID} has been made unobsolete`, {
          title: 'Success!',
          variant: 'success',
          autoHideDelay: 5000,
          solid: true,
        })
        // this.getTableData()
      }).catch(error => { this.handleError(error) })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
